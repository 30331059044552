import React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/layout";
import { Tab } from "../components/articles/tab";
import { Button } from "../components/button";
import { ArticleList } from "../components/articles/ArticleList";
import { Pagination } from "../components/Pagination";
import { Variables } from "../components/designToken";
import PageHeading from "../components/subPageParts/pageHeading";
import SectionBody from "../components/subPageParts/sectionBody";

type Props = {
  data: {
    articles: GatsbyTypes.ContentfulArticleConnection;
    categories: GatsbyTypes.ContentfulCategoryConnection;
  };
  location: Location;
  pageContext: {
    basePath: string;
    slug: string;
    categoryContentfulId?: string | undefined;
    currentPage: number;
    limit: number;
    skip: number;
    totalPages: number;
  };
};

const Articles: React.FC<Props> = ({ data: { articles, categories }, pageContext, location }) => {
  return (
    <>
      <style jsx>{`
        .sectionBody :global(.btn) {
          margin: 60px 0 0px;
        }
        .textAlignCenter {
          text-align: center;
        }
        .headWrapper {
          max-width: 930px;
          padding: 0 30px;
          margin: 0 auto;
        }
        .headWrapper :global(.pageHeading) {
          display: inline-block;
          width: auto;
          margin-right: 100px;
          vertical-align: bottom;
        }
        .headWrapper :global(.tabWrapper) {
          display: inline-block;
          vertical-align: bottom;
        }
        @media (${Variables.breakPoint.sp}) {
          .headWrapper :global(.pageHeading) {
            width: 100%;
            display: block;
            text-align: left;
          }
          .headWrapper :global(.tabWrapper) {
            margin-top: 30px;
            display: block;
          }
        }
      `}</style>
      <Layout>
        <div className="headWrapper">
          <PageHeading title="news" />
          <Tab categories={categories} activeCategoryId={pageContext.categoryContentfulId} />
        </div>
        <div className="sectionBody">
          <SectionBody>
            <div>
              <ArticleList articles={articles} />
              <Pagination
                basePath={pageContext.basePath}
                slug={pageContext.slug}
                currentPage={pageContext.currentPage}
                totalPages={pageContext.totalPages}
              />
            </div>
            <div className="textAlignCenter">
              <Button
                href="https://docs.google.com/forms/d/e/1FAIpQLSf3T7ux08nuHYqqmrqSV9f-VvTdRQAVZNPSeXLG2yUPVApnKw/viewform?usp=sf_link"
                mainText="Contact"
                isTargetBlank
              />
            </div>
          </SectionBody>
        </div>
      </Layout>
    </>
  );
};

export default Articles;

export const ArticlesQuery = graphql`
  query Articles($limit: Int!, $skip: Int!, $categoryContentfulId: String) {
    articles: allContentfulArticle(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
      filter: { category: { contentful_id: { eq: $categoryContentfulId } } }
    ) {
      totalCount
      group(field: category___slug) {
        totalCount
        fieldValue
      }
      edges {
        node {
          id
          contentful_id
          category {
            slug
            name
          }
          ogpImage {
            file {
              url
            }
          }
          date
          title
          service
        }
      }
    }
    categories: allContentfulCategory(sort: { order: DESC, fields: article___category___article }) {
      edges {
        node {
          id
          contentful_id
          slug
          name
        }
      }
    }
  }
`;
