import React from "react";
import { Link } from "gatsby";
import { Color, Variables } from "../designToken";
import rightArrow from "../../images/inline_svg/rightArrow.svg";

type TabItemProps = {
  name: string;
  slug: string;
  isActive?: boolean;
};

const TabItem: React.FC<TabItemProps> = ({ name, slug, isActive }) => {
  const href = slug === `all` ? `/news` : `/news/${slug}/1`;

  return (
    <>
      <style jsx>{`
        li {
          font-size: 20px;
          text-align: center;
          display: inline-block;
          position: relative;
          top: 5px;
          transition: opacity 0.2s ease;
          margin-right: 40px;
        }
        li:hover {
          oapcity: 0.7;
        }
        li :global(a) {
          color: ${Color.brand};
          width: 100%;
          font-weight: 500;
        }
        li img {
          display: inline-block;
          vertical-align: middle;
          margin-left: 5px;
          transform: ${isActive && `rotate(90deg)`};
        }
        li .name {
          display: block;
          font-size: 10px;
          font-weight: 500;
          text-align: left;
        }
        @media (${Variables.breakPoint.sp}) {
          li {
            padding: 10px 0px;
            font-size: 18px;
            margin: 0 10px;
            text-align: left;
          }
        }
        @media (${Variables.breakPoint.smallSp}) {
          li {
            width: calc(50% - 20px);
          }
        }
      `}</style>
      <li>
        <Link to={href}>
          {slug}
          <img src={rightArrow} alt="" />
        </Link>
        <span className="name">{name}</span>
      </li>
    </>
  );
};

type Props = {
  categories: GatsbyTypes.ContentfulCategoryConnection;
  activeCategoryId?: string;
};

export const Tab: React.FC<Props> = ({ categories, activeCategoryId }) => {
  return (
    <>
      <style jsx>{`
        @media (${Variables.breakPoint.sp}) {
          .tabWrapper {
            text-align: center;
          }
          ul {
            margin: 0 auto;
          }
        }
        @media (${Variables.breakPoint.smallSp}) {
          ul {
            max-width: 220px;
          }
        }
      `}</style>
      <div className="tabWrapper">
        <ul>
          <TabItem key="Tab_all" name="すべて" slug="all" isActive={!activeCategoryId} />
          {categories.edges.map((edge) => (
            <TabItem
              key={`Tab_${edge.node.contentful_id}`}
              name={edge.node.name}
              slug={edge.node.slug}
              isActive={activeCategoryId === edge.node.contentful_id}
            />
          ))}
        </ul>
      </div>
    </>
  );
};
