import React from "react";
import { Color, Variables } from "../designToken";
import { Link } from "gatsby";
import DateText from "../dateText";

const ArticleItem: React.FC<{ article: GatsbyTypes.ContentfulArticle }> = ({ article }) => {
  const eyeCatchSrc = article.ogpImage?.file?.url || null;

  return (
    <>
      <style jsx>{`
        li {
          width: 100%;
          margin-bottom: 30px;
          opacity: 1;
          transition: opacity 0.3s ease;
        }
        li:hover {
          opacity: 0.7;
        }
        li :global(a) {
          display: flex;
          align-items: center;
          height: 100%;
          position: relative;
        }
        .imageWrapper {
          position: relative;
          overflow: hidden;
          width: 200px;
          height: 105px;
        }
        .imageWrapper :global(img) {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
          overflow: hidden;
        }
        .imageWrapper :global(.dummyEyeCatch) {
          width: 100%;
          height: 100%;
          border: solid 1px ${Color.lightText};
          border-radius: 4px;
          color: ${Color.lightText};
          text-align: center;
          line-height: 100%;
          font-size: 18px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .imageWrapper :global(.dummyEyeCatch .mediaText) {
        }
        .textWrapper {
          flex: 1;
          width: 100%;
          padding: 0 20px;
          color: ${Color.defaultText};
        }
        .subText {
          color: ${Color.lightText};
          font-size: 15px;
          margin-bottom: 5px;
        }
        .date {
          margin-right: 15px;
          font-size: 16px;
          letter-spacing: 1px;
        }
        .title {
          color: ${Color.defaultText};
          font-size: 18px;
          font-weight: 500;
          line-height: 1.7;
          overflow: hidden;
          position: relative;
        }
        @media (${Variables.breakPoint.smallSp}) {
          li :global(a) {
            display: block;
          }
          .imageWrapper {
            width: 100%;
            height: auto;
            margin: 0;
            position: relative;
          }
          .imageWrapper :global(img) {
            width: 100%;
            position: relative;
            top: 0;
            left: 0;
            transform: translate(0, 0);
            max-width: none;
            max-height: none;
          }
          .dummyEyeCatch {
            display: block;
            padding-top: 50%;
          }
          .mediaText {
            position: absolute;
            display: inline-block;
            width: 100px;
            height: ${16 * 1.8}px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            font-size: 16px;
          }
          .imageWrapper :global(.dummyEyeCatch .mediaText .large) {
            font-size: 20px;
          }
          .textWrapper {
            padding: 15px;
          }
          .title {
            font-size: 16px;
          }
          .subText {
            font-size: 13px;
            margin: 0 0 5px;
            display: flex;
          }
          .date:after {
            content: "|";
            margin-left: 15px;
          }
        }
      `}</style>
      <li>
        <Link to={`/news/${article.contentful_id}`}>
          <div className="imageWrapper">
            {(eyeCatchSrc && <img src={eyeCatchSrc} />) || (
              <div className="dummyEyeCatch">
                <span className="mediaText">
                  <span className="large">M</span>edia
                </span>
              </div>
            )}
          </div>
          <div className="textWrapper">
            <div className="subText">
              <span className="date">
                <DateText dateTime={article.date} />
              </span>
              <span className="category">{article.category.name}</span>
            </div>
            <div className="title">{article.title}</div>
          </div>
        </Link>
      </li>
    </>
  );
};

type Props = {
  articles: GatsbyTypes.ContentfulArticleConnection;
};

export const ArticleList: React.FC<Props> = ({ articles }) => {
  return (
    <ul>
      {articles.edges.map((edge) => (
        <ArticleItem key={`ArticleItem_${edge.node.contentful_id}`} article={edge.node} />
      ))}
    </ul>
  );
};
