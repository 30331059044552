import React from "react";
import { Color, Variables } from "./designToken";
import { Link } from "gatsby";

type PagingItemProps = {
  isActive: boolean;
  to: string;
  page: number;
};

const PagingItem: React.FC<PagingItemProps> = ({ isActive, to, page }) => {
  return (
    <>
      <style jsx>{`
        .paginationItem {
          margin: 0 8px;
        }
        .paginationActiveItem,
        .paginationItem :global(a) {
          display: block;
          line-height: 1;
          font-weight: 500;
          font-size: 30px;
          font-style: italic;
        }
        .paginationActiveItem {
          color: ${Color.defaultText};
        }
        .paginationItem :global(a) {
          color: ${Color.lightText};
          cursor: pointer;
          transition: opacity 0.3s ease;
        }
        .paginationItem :global(a):hover {
          opacity: 0.7;
        }
        @media (${Variables.breakPoint.sp}) {
          .paginationItem {
            font-size: 21px;
          }
        }
      `}</style>
      <li className={`paginationItem ${isActive && `active`}`}>
        {(isActive && <span className="paginationActiveItem">{page}</span>) || <Link to={to}>{page}</Link>}
      </li>
    </>
  );
};

type Props = {
  basePath: string;
  slug: string;
  currentPage: number;
  totalPages: number;
};

export const Pagination: React.FC<Props> = ({ basePath, slug, currentPage, totalPages }) => {
  return (
    <>
      <style jsx>{`
        .wrapper {
          margin: 20px 0;
        }
        .pagination {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        .prev :global(a),
        .next :global(a) {
          font-size: 25px;
          font-weight: 500;
          color: ${Color.lightText};
          cursor: pointer;
          transition: opacity 0.3s ease;
        }
        .prev {
          margin-right: 16px;
        }
        .next {
          margin-left: 16px;
        }
        .prev:hover,
        .next:hover {
          opacity: 0.7;
        }
        @media (${Variables.breakPoint.sp}) {
          .prev,
          .next {
            font-size: 21px;
          }
        }
      `}</style>
      <div className="wrapper">
        <ul className="pagination">
          {currentPage > 1 && (
            <li className="prev">
              <Link to={`${basePath}${currentPage - 1}`}>&lt;</Link>
            </li>
          )}
          {totalPages > 1 &&
            [...Array(totalPages)].map((_, i) => {
              const page = i + 1;
              const to = slug === "all" && page === 1 ? "/news" : `${basePath}${page}`;
              return <PagingItem key={`PagingItem_${i}`} isActive={currentPage === page} to={to} page={page} />;
            })}
          {currentPage < totalPages && (
            <li className="next">
              <Link to={`${basePath}${currentPage + 1}`}>&gt;</Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};
